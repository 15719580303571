import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		userInfo: {
			expires: "",
			user_id: "",
			user_token: "",
			userName: "",
		},
		modalClose: false
	},
	mutations: {
		SET_USERINFO(state, userInfo) {
			state.userInfo = userInfo
		},
	},
	getters: {
		getUserInfo: state => state.userInfo
	}
})

export default store
