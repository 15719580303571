<template>
	<div class="search-info-container">
		<el-form :inline="true" :model="searchForm">
			<slot></slot>
		</el-form>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				searchForm:{}
			}
		}
	}
</script>

<style lang="scss">
	.search-info-container{
		
	}
</style>
