import Vue from 'vue'
import Router from 'vue-router'
import VueCookies from 'vue-cookies'
import container from '../components/layout/container.vue'
import {
	Message
} from 'element-ui'


Vue.use(Router)

const router = new Router({
	routes: [{
		path: '/login',
		name: 'login',
		component: () => import('@/views/login')
	}, {
		path: '/',
		name: 'Main',
		component: () => import('@/components/layout'),
		children: [{
				path: '/',
				name: "Index",
				component: () => import("@/views/index"),
				meta: {
					title: "首页",
					icon: require("@/assets/aside/home.png")
				}
			},
			{
				path: '/aliyun',
				name: "Aliyun",
				component: container,
				meta: {
					title: "阿里云",
					icon: require("@/assets/aside/liyun.png")
				},
				children: [{
					path: "/uid",
					name: "uid",
					component: () => import("@/views/aliyun/uid.vue"),
					meta: {
						title: "UID账号管理"
					}
				}, 
				// {
				// 	path: "/uid2",
				// 	name: "uid2",
				// 	component: () => import("@/views/aliyun/uid.vue"),
				// 	meta: {
				// 		title: "UID账号消费"
				// 	}
				// }
			]
			},
			{
				path: '/client',
				name: "client",
				component: container,
				meta: {
					title: "客户管理",
					icon: require("@/assets/aside/kehu.png")
				},
				children: [{
					path: "/list",
					name: "list",
					component: () => import("@/views/client/list.vue"),
					meta: {
						title: "客户列表"
					}
				}]
			},
			// {
			// 	path: '/aliyun3',
			// 	name: "Aliyun3",
			// 	component: container,
			// 	meta: {
			// 		title: "费用中心",
			// 		icon: require("@/assets/aside/feiyong.png")
			// 	},
			// 	children: [{
			// 		path: "/uid4",
			// 		name: "uid4",
			// 		component: () => import("@/views/aliyun/uid.vue"),
			// 		meta: {
			// 			title: "收支明细"
			// 		}
			// 	}]
			// },
			{
				path: '/system',
				name: "system",
				component: container,
				meta: {
					title: "系统设置",
					icon: require("@/assets/aside/xitong.png")
				},
				children: [{
					path: "/user",
					name: "user",
					component: () => import("@/views/system/user.vue"),
					meta: {
						title: "人员管理"
					}
				}, {
					path: "/role",
					name: "role",
					component: () => import("@/views/system/role.vue"),
					meta: {
						title: "角色权限"
					}
				}, {
					path: "/add_role",
					name: "addRole",
					component: () => import("@/views/system/addRole.vue"),
					meta: {
						title: "新增角色",
						editTitle:"角色管理",
						hidden: true
					}
				}, 
				// {
				// 	path: "/uid6",
				// 	name: "uid6",
				// 	component: () => import("@/views/aliyun/uid.vue"),
				// 	meta: {
				// 		title: "同步记录"
				// 	}
				// }, {
				// 	path: "/uid7",
				// 	name: "uid7",
				// 	component: () => import("@/views/aliyun/uid.vue"),
				// 	meta: {
				// 		title: "操作日志"
				// 	}
				// }
			]
			}, {
				path: "/404",
				name: "404",
				component: () => import("@/views/error/404.vue"),
				meta: {
					hidden: true,
				}
			}, {
				path: "*",
				name: "*",
				redirect: "/404",
				meta: {
					hidden: true
				}
			}
		]
	}]
})

router.beforeEach((to, from, next) => {
	if (to.path == '/login') {
		next()
	} else {
		if (!VueCookies.get('userInfo')|| !VueCookies.get('userInfo').userName) {
			Message({
				message: '账号未登录',
				type: 'error',
				duration: 5 * 1000
			})
			next('/login')
		} else {
			// next()
		}
		next()
	}
})

export default router
