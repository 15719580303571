import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import VueCookies from 'vue-cookies'
import store from './store'
import searchInfo from './components/searchInfo.vue'
import 'babel-polyfill'
Vue.use(VueCookies)
Vue.prototype.$cookies = VueCookies
Vue.component('searchInfo', searchInfo)

Vue.use(ElementUI);
// if (!global._babelPolyfill) {
// 	import('babel-polyfill');
// }

import router from '@/router'

Vue.config.productionTip = false

let width = document.documentElement.clientWidth
if (width < 1200) {
	width = 1200
}
if (width > 1500) {
	width = 1500
}
document.documentElement.style.fontSize = (width / 100 + 'px')
window.onresize = () => {
	width = document.documentElement.clientWidth
	if (width < 1200) {
		width = 1200
	}
	if (width > 1500) {
		width = 1500
	}
	document.documentElement.style.fontSize = (width / 100 + 'px')
}


new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')
