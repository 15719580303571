<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>
	export default {
		name: 'App',
	}
</script>

<style lang="scss">
	body {
		margin: 0;
		padding: 0;
	}

	#app {
		min-height: 100vh;
		min-width: 100vw;
	}

	@media screen and (max-width: 1200px) {
		#app {
			width: 1200px;
		}
	}

	.my-form-container {
		background-color: #ffffff;
		padding: 1.7rem;
		padding-bottom: 1rem;

		.my-form {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr;

			.el-form-item__label {
				font-size: .7rem;
				color: #666666;
				padding: 0;
				min-width: 4.5rem;
			}

			.el-form-item {
				margin-bottom: 1.3rem;
				display: flex;
				align-items: center;
			}

			.el-input {
				width: 13.5rem;

				input {
					width: 13.5rem;
					height: 2.3rem;
					font-size: .7rem;
				}
			}
		}

		.form-button {
			display: flex;
			justify-content: flex-end;

			button {
				width: 94px;
				height: 40px;
				font-size: 14px;
				padding: 0;
			}
		}
	}

	.my-table {
		background-color: #ffffff;
		padding: 1.3rem 1.7rem .9rem 0;
		margin-top: 1.3rem;

		.invite-button {
			width: 100px;
			height: 40px;
			background-color: #3B91FF;
			color: #ffffff;
			font-size: 14px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			img {
				width: .7rem;
				height: .7rem;
				margin-right: .3rem;
			}
		}

		.el-table th.el-table__cell>.cell {
			color: #B1BAC7;
			font-size: 14px;
		}
		.el-table td.el-table__cell div{
			white-space: nowrap;
		}

		.el-table--enable-row-transition .el-table__body td.el-table__cell {
			color: #666666;
			font-size: .7rem;
		}

		.el-table .el-table__cell {
			height: 3.7rem;
			padding: 0;
		}

		.my-page {
			display: flex;
			justify-content: flex-end;
			margin-top: .8rem;

			.el-pager li {
				background-color: #ffffff;
				margin: 0;
			}

			.btn-prev {
				background-color: #ffffff;
			}

			.btn-next {
				background-color: #ffffff;
			}

			.el-pagination__sizes {
				margin-right: 0;
			}

			.el-pagination__jump {
				margin-left: .7rem;
			}
			.number{
				font-weight: normal;
			}
		}
		.el-table th.el-table__cell>.cell{
			padding-left: 14px;
		}
	}

	.my-dialog {
		.el-dialog__header {
			padding: .9rem 0;
			border-bottom: .1rem solid rgba(0, 0, 0, 0.04);

			.el-dialog__title {
				color: #000000;
				font-size: .8rem;
			}
		}

		.el-dialog__footer {
			padding: 0;
		}

		.my-dialog-footer {
			display: flex;
			justify-content: center;
			padding: 0;
			padding-bottom: .6rem;

			button {
				width: 82px;
				height: 32px;
				font-size: 14px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}

	.invite-dialog {
		.el-dialog {
			width: 30.2rem !important;
		}

		.el-dialog__body {
			padding: 1.3rem 2.7rem 3.6rem 1rem;
		}
	}

	.shouxin-dailog {
		.el-dialog {
			width: 45rem !important;
		}

		.el-dialog__body {
			padding: .6rem 1.3rem .6rem 1.3rem;
		}
	}

	.el-form-item {
		display: flex;
		align-items: center;
		margin-bottom: 1.8rem;
	}

	.el-form-item__label {
		font-size: .7rem;
		color: #666666;
		margin-right: .3rem;
		padding: 0;
		line-height: inherit;
		white-space: nowrap;
	}

	.el-form-item__content {
		flex: 1;
		line-height: inherit;
		margin-left: 0 !important;
	}

	.el-input {}

	.el-input__inner {
		height: 2.1rem;
	}

	.el-select .el-input .el-select__caret {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.el-select {
		width: 100%;
	}

	.el-alert {
		padding: .6rem .8rem;

		.el-alert__title {
			font-size: .7rem;
		}
	}

	.el-radio {
		margin-right: .8rem;

		.el-radio__label {
			color: #333333;
			font-size: .7rem;
			padding-left: .4rem;
		}
	}

	.el-button--primary {
		background-color: #3B91FF;
	}
	
	
	.headerPop{
		width: 7.6rem !important;
		padding: 0;
		.user-login-out{
			div{
				height: 2.8rem;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #666666;
				font-size: .7rem;
				cursor: pointer;
				transition: all .3s;
				&:hover{
					background-color: #3B91FF;
					color: #ffffff;
				}
			}
		}
	}
	
	.has-gutter{
		font-weight: normal !important;
		tr th{
			font-weight: normal !important;
			.cell{
				font-weight: normal !important;
			}
		}
	}
	.el-table__fixed-right{
		tr th{
			font-weight: normal;
		}
	}
	.el-table__fixed{
		tr th{
			font-weight: normal;
		}
	}
	.el-table__empty-block{
		.el-table__empty-text{
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: center;
			min-height: 8.7rem;
			justify-content: center;
			line-height: inherit;
			&:before{
				content: "";
				background-image: url(assets/empty-data.png);
				background-size: 100% 100%;
				width: 2.6rem;
				height: 2.6rem;
				margin-bottom: .8rem;
			}
		}
	}
		
		
		
	.el-select-dropdown {
		min-width: 13.5rem !important;
	}
	
	.el-select-dropdown__item.hover,
	.el-select-dropdown__item:hover {
		background-color: #3B91FF;
		color: #ffffff;
	}
	
	.el-alert--warning.is-light {
		background-color: #FFF5EB;
	
		.el-alert__content {
			color: #555555;
		}
	}
	
	input{
		color: #111111 !important;
		font-size: 14px !important;
	}
	
	.sx-body {
		max-height: 8rem;
		overflow-y: scroll;
	}
	
	::-webkit-scrollbar {
		width: .5rem;
	
	}
	
	::-webkit-scrollbar-track {
	
		background-color: #F1F1F1;
	
		-webkit-border-radius: 2em;
	
		-moz-border-radius: 2em;
	
		border-radius: 2em;
	
	}
	
	::-webkit-scrollbar-thumb {
	
		background-color: #C1C1C1;
	
		-webkit-border-radius: 2em;
	
		-moz-border-radius: 2em;
	
		border-radius: 2em;
	
	}
</style>
